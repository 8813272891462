import { useState } from 'react';
import editDeal from '../../../../api/deals/editDeal';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import {
  DEAL_STATUS_COMPLETED,
  DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
  DEAL_STATUS_WAIRING_REGISTRATION,
  DEAL_STATUS_WAITING_FOR_HALYK,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_PAYMENT_ORDER,
  DOCUMENT_TYPES_PLEDGE_AGREEMENT,
  DOCUMENT_TYPES_POLES,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
  DOCUMENT_TYPES_TECH_PASSPORT,
  PAYMENT_STATUS_PAID,
} from '../../../../constants';
import generateAllowedDocumentsJson from '../../../../utils/generateAllowedDocumentsJson';
import HalykCarRegistration from './HalykCarRegistration';
import HalykNotarialDocuments from './HalykNotarialDocuments';
import HalykPaymentStatus from './HalykPaymentStatus';
import alert from '../../../../helpers/alert';
import setStep from '../../../../api/deals/setStep';
import Button from '../../../../components/Button/Button';
import uploadDocumentToDeal from '../../../../api/deals/uploadDocumentToDeal';
import editCar from '../../../../api/cars/editCar';
import sendMarriedDoc from '../../../../api/creditPlatform/sendMarriedDoc';
import HalykCarDelivery from './HalykCarDelivery';
import initialFeeCreditCardPayment from '../../../../api/deals/initialFeeCreditCardPayment';
import ClientPaymentApprove from '../ClientPaymentApprove';
import ContractSign from '../CommonBankSteps/ContractSign';

const HalykFlow = ({ step, deal, documents, update, cars, chosenBankAppealId, bankId }) => {
  const [loading, setLoading] = useState(false);

  const onNotarialDocumentsSubmit = async (values) => {
    setLoading(true);

    if (!values['согласие']) {
      alert.error('Необходимо загрузить согласие супруга (супруги) на внесудебную реализацию авто');
      setLoading(false);
      return;
    }

    await uploadDocumentToDeal({
      dealId: deal?.id,
      fileTypeId: DOCUMENT_TYPES_SPOUSE_CONSENT,
      file: values['согласие'],
    });
    if (values['доверенность']) {
      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        file: values['доверенность'],
      });
    }

    sendMarriedDoc({ appealId: chosenBankAppealId })
      .then(() => {
        editDeal({
          id: deal?.id,
          dealId: deal?.id,
          statusId: DEAL_STATUS_WAIRING_REGISTRATION,
          step: DEAL_STEPS.HALYK.AUTO_REGISTRATION,
          power_of_attorney_number: values?.powerOfAttorneyNumber
            ? values?.powerOfAttorneyNumber
            : deal?.power_of_attorney_number,
          ...generateAllowedDocumentsJson([
            DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
            DOCUMENT_TYPES_INVOICE,
            DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
            DOCUMENT_TYPES_POWER_OF_ATTORNEY,
            DOCUMENT_TYPES_SPOUSE_CONSENT,
            DOCUMENT_TYPES_GUARANTEE_LETTER,
          ]),
        })
          .then(() => {
            update();
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        alert.error('Не удалось отправить согласие супруга (супруги) на внесудебную реализацию авто');
        setLoading(false);
      });
  };

  const onPaymentSubmit = (values) => {
    if (values?.status?.value != PAYMENT_STATUS_PAID) return;

    setLoading(true);
    editDeal({
      id: deal?.id,
      statusPaymentId: values?.status?.value,
      statusId: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
      ]),
    })
      .then(async () => {
        await setStep({ dealId: deal?.id, step: DEAL_STEPS.HALYK.NOTARIAL_VERIFICATION });

        alert.success('Статус оплаты успешно обновлен');
        update && update();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendPaymentSms = () => {
    setLoading(true);
    initialFeeCreditCardPayment({
      bankId: bankId,
      chunkId: deal?.chunkId,
    })
      .then(() => {
        alert.success('Смс со ссылкой на оплату отправлено на номер телефона клиента');
      })
      .catch((err) => {
        alert.error(err?.response?.data?.message || 'Не удалось отправить смс со ссылкой на оплату');
      })
      .finally(() => setLoading(false));
  };

  const onRegistrationSubmit = async (values) => {
    if (!values?.status) {
      alert.error('Необходимо выбрать статус сделки');
      return;
    }
    try {
      await editDeal({
        id: deal?.id,
        dealId: deal?.id,
        statusId: values?.status?.value,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
          DOCUMENT_TYPES_GUARANTEE_LETTER,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_TECH_PASSPORT,
          DOCUMENT_TYPES_CONTRACT,
          DOCUMENT_TYPES_POLES,
          DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
          DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
          DOCUMENT_TYPES_POWER_OF_ATTORNEY,
          DOCUMENT_TYPES_SPOUSE_CONSENT,
          DOCUMENT_TYPES_PAYMENT_ORDER,
        ]),
      });
      if (values?.status?.value === DEAL_STATUS_WAITING_FOR_HALYK) {
        await setStep({ dealId: deal?.id, step: DEAL_STEPS.HALYK.WAITING_FOR_FINAL_ACTIONS });
      }

      return update();
    } catch (e) {
      alert.error('Ошибка при смене статуса сделки');
      setLoading(false);
      return;
    }

    try {
      await editCar(cars?.[0]?.id, {
        grnz: values['grnz'],
      });
      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_TECH_PASSPORT,
        file: values['Техпаспорт'],
      });
      await editDeal({
        id: deal?.id,
        statusId: DEAL_STATUS_COMPLETED,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
          DOCUMENT_TYPES_GUARANTEE_LETTER,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_TECH_PASSPORT,
          DOCUMENT_TYPES_CONTRACT,
          DOCUMENT_TYPES_POLES,
          DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
          DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
          DOCUMENT_TYPES_POWER_OF_ATTORNEY,
          DOCUMENT_TYPES_SPOUSE_CONSENT,
        ]),
      });
      await setStep({ dealId: deal?.id, step: 10 });
      update && update();
    } catch (e) {
      alert.error('Ошибка при загрузке документа');
    }
  };

  const onCarDeliverySubmit = async (values) => {
    if (!values?.status) {
      alert.error('Необходимо выбрать статус сделки');
      return;
    }
    setLoading(true);
    try {
      await editDeal({
        id: deal?.id,
        dealId: deal?.id,
        statusId: values?.status?.value,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
          DOCUMENT_TYPES_GUARANTEE_LETTER,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_TECH_PASSPORT,
          DOCUMENT_TYPES_CONTRACT,
          DOCUMENT_TYPES_POLES,
          DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
          DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
          DOCUMENT_TYPES_POWER_OF_ATTORNEY,
          DOCUMENT_TYPES_SPOUSE_CONSENT,
        ]),
      });

      await setStep({ dealId: deal?.id, step: 10 });
      setLoading(false);
      return update();
    } catch (e) {
      alert.error('Ошибка при смене статуса сделки');
      setLoading(false);
      return;
    }
  };



  const goBack = (statusId, step) => {
    setLoading(true);
    editDeal({
      id: deal?.id,
      statusId,
      step,
    })
      .then(() => {
        update && update();
      })
      .finally(() => setLoading(false));
  };

  const onContractSmsSent = () => {
    alert.success("СМС со ссылкой на подписание договора успешно отправлено");
  };

  if (loading) {
    return <LoaderInline />;
  }

  if(step === DEAL_STEPS.HALYK.SIGN_CONTRACT) {
    return <ContractSign deal={deal} documents={documents} cars={cars} dealStepAfterSign={DEAL_STEPS.HALYK.WAITING_FOR_PAYMENT} setLoading={setLoading} onSuccess={onContractSmsSent} update={update}/>;
  }

  if (step === DEAL_STEPS.HALYK.NOTARIAL_VERIFICATION) {
    return (
      <>
        <HalykNotarialDocuments documents={documents} loading={loading} onSubmit={onNotarialDocumentsSubmit} />
      </>
    );
  }

  if (step === DEAL_STEPS.HALYK.WAITING_FOR_PAYMENT) {
    return (
        <HalykPaymentStatus
          onSubmit={onPaymentSubmit}
          documents={documents}
          deal={deal}
          sendPaymentSms={sendPaymentSms}
        />
    );
  }
  if (step === DEAL_STEPS.HALYK.AUTO_REGISTRATION) {
    return (
      <>
        <HalykCarRegistration onSubmit={onRegistrationSubmit} />
      </>
    );
  }
  if (step === DEAL_STEPS.HALYK.WAITING_FOR_FINAL_ACTIONS) {
    return (
      <div>
        <p
          style={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '20px',
            color: 'rgb(73, 65, 54)',
            marginBottom: '20px',
          }}
        >
          Статус
        </p>
        <Button
          text={'Ожидаем завершения действий клиента на стороне Народного банка'}
          style={{ color: 'rgb(73, 65, 54)', border: '1px solid rgb(73, 65, 54)' }}
        />
      </div>
    );
  }
  if (step === DEAL_STEPS.HALYK.CAR_DELIVERY) {
    return (
      <div>
        <HalykCarDelivery onSubmit={onCarDeliverySubmit} loading={loading} />
      </div>
    );
  }
};

export default HalykFlow;
