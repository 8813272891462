
import { useMemo, useState } from 'react';
import Select from '../../components/Select/Select';
import { BANKS, DEAL_STEPS } from '../../constants';
import { textStyles } from '../../constants/textStyles';
import Button from '../../components/Button/Button';
import setStep from '../../api/deals/setStep';
import { useSelector } from 'react-redux';

const DealConfigureSteps = ({bankId, dealId, update}) => {
    const [statudId, setStatusId] = useState(null);
    const userId = useSelector((state) => state?.user?.id)

    const options = useMemo(() => {
        
        const bankKeys = Object.keys(BANKS);
        let bankName;

        for(let i of bankKeys) {
            if(BANKS?.[i] == bankId) {
                bankName = i;
            }
        }

        console.log("BANKID", bankId, bankName, DEAL_STEPS?.[bankName]);

        if(!bankId || !bankName || !DEAL_STEPS?.[bankName]) return null;
        
        const keys = Object.keys(DEAL_STEPS?.[bankName])

        return keys?.map((key) => {
            return {
                label: key,
                value: DEAL_STEPS?.[bankName]?.[key]
            }
        })
    }, [bankId])
    
    if(!bankId || !options) {
        return null;
    }

    const submit = () => {
        setStep({ dealId, step: statudId }).then(() => {
            update();
        });
    };

    if(userId != 1) {
        return;
    }

    return(<div>
        <p style={{ ...textStyles.formTitle, marginBottom: 20, marginTop: 40 }}>Редактирование этапа сделки:</p>

        <div style={{marginBottom: 20}}>
            <Select onChange={(option) => setStatusId(option?.value)} label="Этап сделки" options={options} />
        </div>
        <Button onClick={submit} fill disabled={!statudId}>Сохранить</Button>
    </div>)
}

export default DealConfigureSteps;